import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { indicatorsValueStore } from '~/pages/winnerA/modules/indicatorsValueStore';
export const winner_tx_attack = createIndicator({
    displayName: '攻擊線1',
    id: 'winnertxattack',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const in1 = 9;
            const in2 = 20;
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
            const sma2 = this.PineJS.Std.sma(close_array, in2, this._context);
            const colorIndex = sma1 - sma2 >= 0 ? 0 : 1;
            indicatorsValueStore.attackLine = sma1;
            return [sma1, colorIndex];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'colorer',
                target: 'plot_0',
                palette: 'palette_0',
            },
        ],
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                },
            },
        },
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff0080',
                },
            },
            palettes: {
                palette_0: {
                    colors: {
                        0: {
                            color: '#ff0000',
                            width: 3,
                            style: 0,
                        },
                        1: {
                            color: '#00ff00',
                            width: 3,
                            style: 0,
                        },
                    },
                },
            },
            // inputs: { in_0: 9 },
        },
        styles: {
            plot_0: {
                title: '攻擊線顏色',
                histogramBase: 0,
            },
        },
        is_price_study: !0,
        inputs: [
        // { id: 'in_0', name: '攻擊線均線', defval: 9, type: 'integer', min: 1, max: 100 }
        ],
        scriptIdPart: '',
    },
});
