import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { indicatorsValueStore } from '~/pages/winnerA/modules/indicatorsValueStore';
export const winner_test_bsti = createIndicator({
    id: 'winnertestbsti',
    displayName: '籌碼力道方向TX',
    constructorScope: {
        init(context, inputcallback) {
            const ticker1 = this.PineJS.Std.ticker(context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol1 = ticker1 + '#TIBS';
            //const symbol1 = ticker1 + '#BS'
            context.new_sym(symbol1, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            context.select_sym(1);
            const h = this.PineJS.Std.high(this._context);
            const colorIndex = h >= 0 ? 0 : 1;
            indicatorsValueStore.bigtraderChip = h;
            return [h, colorIndex];
        },
    },
    metainfo: {
        precision: 2,
        //宣告
        plots: [
            {
                id: 'vol',
                type: 'line',
            },
            {
                id: 'volumePalette',
                type: 'colorer',
                target: 'vol',
                palette: 'volumePalette',
            },
        ],
        palettes: {
            volumePalette: {
                colors: {
                    0: {
                        name: '能量',
                    },
                    1: {
                        name: '能量',
                    },
                },
            },
        },
        defaults: {
            //樣式調整
            styles: {
                //--
                vol: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff8800',
                },
            },
            //填充區域
            palettes: {
                volumePalette: {
                    colors: {
                        0: {
                            color: '#ff00ff',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#00ffff',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
        },
        styles: {
            vol: {
                title: '能量',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
    },
});
