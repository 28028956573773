import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const winner_test_bs0 = createIndicator({
    id: 'winnertestbs0',
    displayName: '主力方向',
    constructorScope: {
        init(context, inputcallback) {
            const ticker = this.PineJS.Std.ticker(context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#bs_indicator_acc';
            context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            context.select_sym(1);
            const h = this.PineJS.Std.high(this._context);
            return [h];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [{ id: 'plot_0', type: 'line' }],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 4,
                    trackPrice: false,
                    transparency: 0,
                    color: '#0000ff',
                },
            },
            // Precision is 0 digit, only integer
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '大戶',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
