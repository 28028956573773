import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const winner_tx_big = createIndicator({
    displayName: '大戶作價企圖',
    id: 'winnertxbig',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const s = this.PineJS.Std.close(this._context);
            const a = this.PineJS.Std.high(this._context);
            const l = this.PineJS.Std.low(this._context);
            const c = this._context.new_var(s);
            const u = this._context.new_var(a);
            const h = this._context.new_var(l);
            const itime = this.PineJS.Std.time(this._context);
            const time_hours = new Date(itime).getHours();
            const time_minutes = new Date().getMinutes();
            //T盤----------------------------------------------
            const in0 = 33;
            const in1 = 4;
            const in2 = 8;
            const d1 = this.PineJS.Std.stoch(c, u, h, in0, this._context);
            const p1 = this._context.new_var(d1);
            const g1 = this.PineJS.Std.sma(p1, in1, this._context);
            const f1 = this._context.new_var(g1);
            const j1 = this.PineJS.Std.sma(f1, in2, this._context);
            //T+1盤----------------------------------------------
            const in3 = 53;
            const in4 = 12;
            const in5 = 9;
            const d2 = this.PineJS.Std.stoch(c, u, h, in3, this._context);
            const p2 = this._context.new_var(d2);
            const g2 = this.PineJS.Std.sma(p2, in4, this._context);
            const f2 = this._context.new_var(g2);
            const j2 = this.PineJS.Std.sma(f2, in5, this._context);
            let value1 = NaN;
            let line1_color = NaN;
            let value2 = NaN;
            let line2_color = NaN;
            if (time_hours >= 8 && time_hours <= 15) {
                value1 = (f1.get(0) - 50) * 1000;
                line1_color = f1 <= j1 ? 0 : 1;
                value2 = NaN;
                line2_color = NaN;
            }
            if (time_hours >= 15) {
                value1 = NaN;
                line1_color = NaN;
                value2 = (f2.get(0) - 50) * 1000;
                line2_color = f2 <= j2 ? 0 : 1;
            }
            if (time_hours < 8) {
                value1 = NaN;
                line1_color = NaN;
                value2 = (f2.get(0) - 50) * 1000;
                line2_color = f2 <= j2 ? 0 : 1;
            }
            return [value1, value2, line1_color, line2_color, 0];
        },
    },
    metainfo: {
        precision: 2,
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'colorer',
                target: 'plot_0',
                palette: 'palette_0',
            },
            {
                id: 'plot_3',
                type: 'colorer',
                target: 'plot_1',
                palette: 'palette_1',
            },
            {
                id: 'plot_4',
                type: 'line',
            },
        ],
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                },
            },
            palette_1: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                },
            },
        },
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 7,
                    transparency: 20,
                    visible: !0,
                    color: '#0099ff',
                },
                plot_1: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 7,
                    transparency: 0,
                    visible: !0,
                    color: '#0099ff',
                },
                plot_4: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    transparency: 0,
                    visible: !0,
                    color: '#eaeaea',
                },
            },
            palettes: {
                palette_0: {
                    colors: {
                        0: {
                            color: '#00ff00',
                            width: 2,
                            style: 0,
                        },
                        1: {
                            color: '#FF0000',
                            width: 2,
                            style: 0,
                        },
                    },
                },
                palette_1: {
                    colors: {
                        0: {
                            color: '#00bcd4',
                            width: 2,
                            style: 0,
                        },
                        1: {
                            color: '#FF0000',
                            width: 2,
                            style: 0,
                        },
                    },
                },
            },
        },
        styles: {
            plot_0: {
                title: 'T',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'T+1',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_4: {
                title: '0',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
    },
});
