import { proxy } from 'valtio';
/** 專屬 winnerA 指標傳參數 */
export const indicatorsValueStore = proxy({
    symbol: '',
    /** 現價 */
    close: NaN,
    /** 當沖力道 */
    daytradePower: NaN,
    /** 趨勢力道 */
    trendPower: NaN,
    /** 成本線 */
    constLine: NaN,
    /** 攻擊線 */
    attackLine: NaN,
    /** 大戶籌碼 */
    bigtraderChip: NaN,
    /** 趨勢對決線上 趨勢對決線下*/
    trendLine: [NaN, NaN],
    /** 趨勢對決線上 趨勢對決線下*/
    bumpyLine: [NaN, NaN],
});
export const resetIndicatorsValue = () => {
    indicatorsValueStore.close = NaN;
    indicatorsValueStore.daytradePower = NaN;
    indicatorsValueStore.trendPower = NaN;
    indicatorsValueStore.constLine = NaN;
    indicatorsValueStore.attackLine = NaN;
    indicatorsValueStore.bigtraderChip = NaN;
    indicatorsValueStore.trendLine = [NaN, NaN];
    indicatorsValueStore.bumpyLine = [NaN, NaN];
    indicatorsValueStore.trendLine = [NaN, NaN];
};
