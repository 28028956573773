import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const winner_tx_trend = createIndicator({
    displayName: '贏家主趨勢',
    id: 'winnertxtrend',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            //RSV
            const input0 = 18;
            //K
            const input1 = 8;
            //D
            const input2 = 6;
            //SAR1
            const input3 = 0.0136;
            //SAR2
            const input4 = 0.0002;
            //SAR3
            const input5 = 0.02;
            const in0 = input0;
            const in1 = input1;
            const in2 = input2;
            const sar0 = input3;
            const sar1 = input4;
            const sar2 = input5;
            const sar_value0 = this.PineJS.Std.sar(sar0, sar1, sar2, this._context);
            const itime = this.PineJS.Std.time(this._context);
            const close = this.PineJS.Std.close(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const close_array = this._context.new_var(close);
            const high_array = this._context.new_var(high);
            const low_array = this._context.new_var(low);
            const vHn = this.PineJS.Std.highest(high_array, in0, this._context);
            const vLn = this.PineJS.Std.lowest(low_array, in0, this._context);
            const vrsv = ((close - vLn) / (vHn - vLn)) * 100;
            const vk_array = this._context.new_var();
            const s = (1.0 / in1) * vrsv + (1 - 1.0 / in1) * vk_array.get(1);
            if (isNaN(s)) {
                vk_array.set(0);
            }
            else {
                vk_array.set(s);
            }
            const vd_array = this._context.new_var();
            const q = (1.0 / in2) * vk_array + (1 - 1.0 / in2) * vd_array.get(1);
            if (isNaN(q)) {
                vd_array.set(0);
            }
            else {
                vd_array.set(q);
            }
            const colorIndex = vk_array.get(0) >= vd_array.get(0) ? 0 : 1;
            const sarColorIndex = close > sar_value0 ? 0 : 1;
            return [
                100,
                -100,
                (vk_array.get(0) - vd_array.get(0)) * 8,
                sarColorIndex,
                sarColorIndex,
                colorIndex,
            ];
        },
    },
    metainfo: {
        precision: 2,
        //宣告
        plots: [
            {
                id: 'sar1',
                type: 'line',
            },
            {
                id: 'sar2',
                type: 'line',
            },
            {
                id: 'vol',
                type: 'line',
            },
            {
                id: 'sarPalette1',
                type: 'colorer',
                target: 'sar1',
                palette: 'sarPalette1',
            },
            {
                id: 'sarPalette2',
                type: 'colorer',
                target: 'sar2',
                palette: 'sarPalette2',
            },
            {
                id: 'volumePalette',
                type: 'colorer',
                target: 'vol',
                palette: 'volumePalette',
            },
        ],
        palettes: {
            sarPalette1: {
                colors: {
                    0: {
                        name: '多',
                    },
                    1: {
                        name: '空',
                    },
                },
            },
            sarPalette2: {
                colors: {
                    0: {
                        name: '多',
                    },
                    1: {
                        name: '空',
                    },
                },
            },
            volumePalette: {
                colors: {
                    0: {
                        name: '能量',
                    },
                    1: {
                        name: '能量',
                    },
                },
            },
        },
        defaults: {
            //樣式調整
            styles: {
                //--
                plot_0: {
                    transparency: 0,
                    visible: !0,
                },
                plot_1: {
                    transparency: 0,
                    visible: !0,
                },
                plot_2: {
                    transparency: 0,
                    visible: !0,
                },
                //--
                sar1: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 20,
                    visible: !0,
                    color: '#ff8800',
                },
                sar2: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 20,
                    visible: !0,
                    color: '#ff8800',
                },
                vol: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff8800',
                },
            },
            //填充區域
            palettes: {
                sarPalette1: {
                    colors: {
                        0: {
                            color: '#ff0000',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#0000ff',
                            width: 0,
                            style: 0,
                        },
                    },
                },
                sarPalette2: {
                    colors: {
                        0: {
                            color: '#ff0000',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#0000ff',
                            width: 0,
                            style: 0,
                        },
                    },
                },
                volumePalette: {
                    colors: {
                        0: {
                            color: '#ff00ff',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#00ffff',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
            // inputs: { in_0: 18, in_1: 8, in_2: 6, in_3: 0.0136, in_4: 0.0002, in_5: 0.2 },
        },
        styles: {
            vol: {
                title: '能量',
                histogramBase: 0,
                joinPoints: !1,
            },
            sar1: {
                title: '能量',
                histogramBase: 0,
                joinPoints: !1,
            },
            sar2: {
                title: '能量',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [
        // { id: 'in_0', name: 'KD-RSV', defval: 18, type: 'integer', min: 1, max: 100 },
        // { id: 'in_1', name: 'KD-K', defval: 8, type: 'integer', min: 1, max: 100 },
        // { id: 'in_2', name: 'KD-K', defval: 6, type: 'integer', min: 1, max: 100 },
        // { id: 'in_3', name: 'sar初始', defval: 0.0136, type: 'float', min: 0, max: 100 },
        // { id: 'in_4', name: 'sar增量', defval: 0.0002, type: 'float', min: 0, max: 100 },
        // { id: 'in_5', name: 'sar最大', defval: 0.2, type: 'float', min: 0, max: 100 },
        ],
        scriptIdPart: '',
    },
});
